import React from "react";
import heroBg from "../../assets/hero-bg-min.jpg";

const Hero = () => {
  return (
    <main
      className="bg-cover lg:h-screen bg-center bg-no-repeat bg-fixed"
      style={{
        backgroundImage: `url(${heroBg})`,
      }}
    >
      <div class="mx-auto py-48 lg:py-32">
        <div class="text-center text-white">
          <h1 class="text-3xl	lg:text-7xl mx-5 lg:mx-72 font-bold">
            Transforming the way you do business with AI
          </h1>

          <p class="mt-12 lg:mt-10 mx-5 lg:mx-96 text-sm lg:text-2xl leading-snug">
            AiMInds Bangladesh's 1st "AI FIRST" digital marketing agency. At
            AiMinds, we utilize AI technologies to enhance human cognitive
            capabilities and digital design strategies that capitalize on the
            opportunities presented by AI.
          </p>
        </div>
      </div>
      {/* <div className="absolute inset-20 bg-black/30"></div> */}
    </main>
  );
};

export default Hero;

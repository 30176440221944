import React from "react";

const Description = () => {
  return (
    <div class="mx-auto py-24 lg:py-32 ">
      <h1 class="text-center text-3xl lg:text-4xl font-bold mx-2">
        Why AiMinds matters?
      </h1>
      <p class="mt-24 lg:mt-32 mx-10 lg:mx-96 text-xl lg:text-3xl font-light text-gray-700 leading-snug">
        We provide AI-based solutions for small to large companies, with a focus
        on increasing brand awareness, generating leads, and enhancing online
        sales by leveraging machine learning to make sense of big data. Our
        expertise in strategy and AI allows us to deliver ROI and value to our
        clients through services such as SEO and SEM optimization, web
        analytics, and social media management.
      </p>
    </div>
  );
};

export default Description;

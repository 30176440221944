import React from "react";

const Footer = () => {
  return (
    <div class=" bg-black py-10">
      <div class="text-center text-white/75 hover:text-white fill-white/50">
        <p className="mx-8 lg:mx-36">
          &copy; {new Date().getFullYear()}, Shark & Hawk. Made with ❤️ by The
          Tech Team
        </p>
      </div>
    </div>
  );
};

export default Footer;

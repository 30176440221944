import React from "react";
import Description from "../components/home/Description";
import Footer from "../components/home/Footer";
import Hero from "../components/home/Hero";
import SocialMedia from "../components/home/SocialMedia";

const Home = () => {
  return (
    <div>
      <Hero />
      <Description />
      <SocialMedia />
      <Footer />
    </div>
  );
};

export default Home;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const SocialMedia = () => {
  return (
    <div className="container pb-12">
      <div className="flex">
        <ul className="inline-flex mx-auto">
          {/* --------------- Linkedin --------------- */}
          <a
            href="https://www.linkedin.com/company/ai-minds-igital/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="flex justify-center items-center flex-col m-2 text-white bg-black rounded-full w-12 h-12">
              <FontAwesomeIcon icon={faLinkedinIn} size="xl" />
            </li>
          </a>

          {/* --------------- Instagram --------------- */}
          <a
            href="https://www.instagram.com/aiminds.digital/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="flex justify-center items-center flex-col m-2 text-white bg-black rounded-full w-12 h-12">
              <FontAwesomeIcon icon={faInstagram} size="xl" />
            </li>
          </a>

          {/* --------------- Twitter --------------- */}
          <a
            href="https://twitter.com/aimindsdigital"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li className="flex justify-center items-center flex-col m-2 text-white bg-black rounded-full w-12 h-12">
              <FontAwesomeIcon icon={faTwitter} size="xl" />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default SocialMedia;
